import React, { useState, useEffect, Suspense, lazy } from "react";
import {
  Route,
  Routes as Switch,
  BrowserRouter as Router,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { auth } from "./config/firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { setLocationId, setLocationName } from "./actions/BookingAction";
import "react-toastify/dist/ReactToastify.css";
import Svg_animation from "./components/svg_animation.js";
import Loader from "./components/LoaderAnimation/Loader";
import BillingPage from "./pages/BillingPage";
import FormComponent from "./pages/FormComponent.js";
import GroupPayment from "./pages/GroupPayment.js";
import OrderComponent from "./components/OrderComponent.js";
import LoginPage from "./pages/LoginPage.jsx";
import LogoLoader from "./components/logoloader/LogoLoader.jsx";
import NavBarComp from "./pages/NavBarComp.jsx";
import LocationDataComp from "./pages/LocationDataComp.jsx";
import Homepage from "./pages/Homepage.jsx";
import ProtectedRoute from "./components/ProtectedRoute.jsx";
import NotFoundPage from "./pages/NotFoundPage.jsx";
import PortraitVideo from "./components/portraitvideo/PortraitVideo.jsx";
import AmberFortBookingPage from "./pages/AmberFortBookingPage.jsx";
import AmberFortPage from "./pages/AmberFortPage.jsx";
const Login = lazy(() => import("./pages/Login.js"));
const LocationData = lazy(() => import("./pages/locationdata.js"));
const Dashboard = lazy(() => import("./pages/DashBoard.js"));
function App() {
  const [user, loading, error] = useAuthState(auth);
  const [urlLocation, setUrlLocation] = useState("");
  const [showFullPortrait,setShowFullPortrait]=useState(false);
  const dispatch = useDispatch();
  const bookingsData = useSelector((store) => store.bookings);


  var mailLocationsMap = {
    "thrillfactorys@gmail.com": "the-thrill-factory",
    "retargeting_thrill@droame.com":"the-thrill-factory",
    "thrill_factory_videos@gmail.com": "the-thrill-factory",
    "akshit7425@gmail.com": "the-thrill-factory",
    "thepinkfalcon@gmail.com": "the-pink-falcon",
    "f9gokarting@gmail.com": "F9-Go-Karting",
    "splashbungee@gmail.com": "Splash-Bungee",
    "kulluparagliding@gmail.com": "Kullu-Paragliding",
    "udaipurbungee@gmail.com": "Udaipur-Bungee",
    "droameoffice@gmail.com": "Droame-Office",
    "themanalibungee@droame.com":"the-manali-bungee",
    "amerfort@droame.com":"amber-fort"
  };


  var locationIdMap = {
    "thrillfactorys@gmail.com": "ad49e503-d48d-448b-af97-00d0365e2a28",
    "thrill_factory_videos@gmail.com": "ad49e503-d48d-448b-af97-00d0365e2a28",
    "retargeting_thrill@droame.com":"ad49e503-d48d-448b-af97-00d0365e2a28",
    "akshit7425@gmail.com": "ad49e503-d48d-448b-af97-00d0365e2a28",
    "thepinkfalcon@gmail.com": "the-pink-falcon",
    "f9gokarting@gmail.com": "407fadef-f0af-46bd-ae1e-4d148e32b9a1",
    "splashbungee@gmail.com": "430021b2-8468-4e76-9dc7-88a9231d6df3",
    "kulluparagliding@gmail.com": "a614095a-4b85-4482-8d33-191e30dcf42e",
    "udaipurbungee@gmail.com": "e7f98a91-5ba0-48ca-81a7-a9c9d7ae96c2",
    "droameoffice@gmail.com": "9e0f4948-1533-4723-b0ee-325a5e7c7812",
    "themanalibungee@droame.com":"16a3f9c1-c73e-4814-a38e-971ab10ae113",
    "amerfort@droame.com":"f347809d-5700-5bec-fc1f-3b179ba42dd2"
  };

  let email = localStorage.getItem("userEmail");

  // useEffect(() => {
  //   for (let e in urlMap) {
  //     if (e == email || e == user?.email) {
  //       setUrlLocation(urlMap[e]);
  //       localStorage.setItem("currLocation", urlMap[e]);
  //       dispatch(setLocationId(locationIdMap[e]));
  //     }
  //   }
  // }, [user, loading, error]);

  // useEffect(() => {
  //   email = localStorage.getItem("userEmail");
  //   for (let e in urlMap) {
  //     if (e == email || e == user?.email) {
  //       setUrlLocation(urlMap[e]);
  //       localStorage.setItem("currLocation", urlMap[e]);
  //     }
  //   }
  // }, []);

  useEffect(()=>{
    if (!loading && !error && user && user.email) {
        // localStorage.setItem("user_token", user?.accessToken);
        // localStorage.setItem("user_email", user?.email);
        // localStorage?.setItem("user_location" , mailLocationsMap[user?.email]);
        dispatch(setLocationId(locationIdMap[user?.email]));
        dispatch(setLocationName(mailLocationsMap[user?.email]));
      }else if(!user){
        
      }
  },[user , loading]);

  let lUrl = localStorage.getItem("user_location");
  let userEmail = localStorage.getItem("user_email");
    
  if(loading){
     return (<LogoLoader/>);
  }
  // console.log({user , loading , error});
  return (
    <>
      {/* <Svg_animation /> */}
      <ToastContainer />
      
      <Router>
          {user && user?.email && !showFullPortrait && <NavBarComp />}
            <Suspense fallback={<Loader />}>
          <Switch>
         
             <>
                <Route exact path="/login" element={<LoginPage />}/>
				
                <Route  exact path="/" element={<ProtectedRoute><Homepage /></ProtectedRoute>}/>
                  <Route  index exact path="/:location/customer-video/" element={<ProtectedRoute>{showFullPortrait ? <PortraitVideo setShowFullPortrait={setShowFullPortrait}/> : <LocationDataComp setShowFullPortrait={setShowFullPortrait}/>}</ProtectedRoute>}/>
                  <Route exact path="/submitForm/:ids"  element={<ProtectedRoute><FormComponent /></ProtectedRoute>} />
                  {user && user?.email && (lUrl === "F9-Go-Karting" ||
                    userEmail === "splashbungee@gmail.com" ||
                    lUrl === "Kullu-Paragliding" ||
                    lUrl === "Udaipur-Bungee") && <Route  exact path="/:location/dashboard/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}/>}
                  <Route
                    exact
                    path={`/${urlLocation}/billings`}
                    element={<ProtectedRoute><BillingPage /></ProtectedRoute>}
                  />
                    <Route
                    exact
                    path={`/${urlLocation}/portrailVideo`}
                    element={<ProtectedRoute><BillingPage /></ProtectedRoute>}
                  /> 
                 <Route  exact path="*" element={<NotFoundPage/>}/>
                 <Route exact path="/:location/customer-video/createbooking" element={<ProtectedRoute><AmberFortBookingPage /></ProtectedRoute>} />
              </>
           {/* <main className="flex h-screen"> </main> */}

          </Switch>
        </Suspense>
      </Router>

       

      
    </>
  );
}

export default App;
