import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import VideoPlayerComp from '../components/VideoPlayerComp';
import TableRowComp from '../components/TableRowComp';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import { fetchGroupsData } from "../actions/GroupPaymentAction.js";
import { MdOutlineDeleteForever } from "react-icons/md";
import { LuRefreshCw } from "react-icons/lu";
import {
    GetAllSublocationsInALocation,
    GetLocationDetails,
    GetReportBookings,
    GetRetargetingReportBookings,
    getFeedbackQuestions,
  } from "../services/api";
  import {
    setCurrentDate,
    setLocationName,
    setSubLocationActivityType,
    setSubLocationDetails,
    setSubLocationName,
  } from "../actions/BookingAction";
  import { fetchData } from "../actions/BookingAction.js";
import SimpleLogoLoader from '../components/logoloader/SimpleLogoLoader.jsx';
import { FcDeleteDatabase } from "react-icons/fc";
import { closeVideo } from '../actions/CustomerAction.js';
import { useMediaQuery } from 'react-responsive';
import Accordian, { AccordianItem } from '../components/Accordian.jsx';
import GroupMergeModal from '../components/GroupMergeModal.js';
import GrpMerModal from '../components/GrpMerModal.jsx';
import SwitcherBtn from '../components/SwitcherBtn.jsx';
import { Switch } from '@mui/material';
import DeleteBookingsConfirmationModal from '../components/DeleteBookingsConfirmationModal.jsx';
import PaymentCheckoutPage from '../components/PaymentCheckoutPage.jsx';
import { HiOutlineFilter } from "react-icons/hi";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../config/firebase-config.js';
import CustomModal from '../components/Custommodal/CustomModal.jsx';


import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { toast } from 'react-toastify';
import ReportModal from '../components/ReportModal.jsx';
import AmberFortPage from './AmberFortPage.jsx';

const LocationDataComp = ({setShowFullPortrait}) => {
      //Modal
      const bookingsData = useSelector((store) => store.bookings);
      const groupsData = useSelector((state) => state.groupPayment);
      let customerData = useSelector((store) => store.customer);
      const dispatch = useDispatch();
      
    const [date, setDate] = useState(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1 to get the actual month number
        const day = date.getDate();
        const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}`;
        return formattedDate;
      });
      const [user, loading, error] = useAuthState(auth);
    const [locationDetail , setLocationDetail]=useState(null);
    const [subLocationList, setSubLocationList] = useState([]);
    const [selectedSubLocation, setSelectedSubLocation] = useState(bookingsData?.sub_location_details);
    const [searchResults, setSearchResults] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [groupEditMode, SetGroupEditMode] = useState(false);
    const [showGrpMergeModal, setShowGrpMergeModal] = useState(false);
    const [bookingEditMode , setBookingEditMode]=useState(true);
    const [selectedBookings , setSelectedBookings]=useState([]);
    const [openDeleteBookingConfirmationModal , setDeleteBookingConfirmationModal]=useState(false);
    const [groupsWithBookings ,setGroupsWithBookings]=useState([]);
    const [multipleBookingPayment , setMultiplebookingPayment]=useState(false);
    const [openMultipleBookingPaymetModal ,setOpenMultipleBookingPaymentModal]=useState(false)
    const [openFilter ,setOpenFilter]=useState(false);
    const [currentFilter , setCurrentFilter]=useState(user?.email === "retargeting_thrill@droame.com" ? "video_uploaded" : "All");
    
    const mobileScreen = useMediaQuery({ query: '(max-width: 750px)' });
    const smallScreen = useMediaQuery({ query: '(max-width: 600px)' });
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [reportModalOpen , setReportModalOpen]=useState(false);
    const [reportBookings , setReportBookings]=useState();
    const [reportLoading , setReportLoading]=useState(false);

    const customStyles = {
      content: {
         zIndex:'20',
         padding:'10px',
         boxShadow: '0px 0px 41px 5px rgba(0,0,0,0.1)',
         borderRadius:'12px',
         inset: `${smallScreen ? '10px' : '40px'}`
      },
    };


     
     //* Feedback GET Api Call */
        const fetchFeedbackQuestions = async () => {
            try {
              const response = await getFeedbackQuestions();
              localStorage.setItem('feedbackApiData',JSON.stringify(response?.data?.data?.question));
            } catch (error) {
              console.error("Error fetching feedback questions:", error);
            }
          };
     

           useEffect(() => {
            const fetchData = async () => {
                // console.log("main _-- useEffect")
              try {
                const locationDetailsPromise = GetLocationDetails(bookingsData.location_id);
                const subLocationDetailsPromise = GetAllSublocationsInALocation(bookingsData.location_id);
          
                const [locationDetailsResponse, subLocationDetailsResponse ] = await Promise.all([
                  locationDetailsPromise,
                  subLocationDetailsPromise,
                ]);
          
                const locationDetails = locationDetailsResponse?.data?.location_details;
                const subLocationList = subLocationDetailsResponse?.data?.sub_locations;
                // Set state only if both API calls succeed
                setLocationDetail(locationDetails);
                setSubLocationList(subLocationList);
     
              } catch (error) {
                console.error("Error fetching data:", error);
                // Handle errors, e.g., show an error message to the user
              }
            };
            if (bookingsData?.location_name !== "amber-fort" && bookingsData?.location_id && subLocationList?.length < 1) {
              fetchData();
            }
          }, [bookingsData.location_id]);

          
          useEffect(() => {
                if (bookingsData?.location_name !== "amber-fort" &&  bookingsData?.sub_location_details && bookingsData?.date &&  bookingsData.sub_location_details?.sub_location_id) {
                    // console.log("bookings-deatil-working !!! =========================> ");
                    dispatch(
                        fetchData(
                            bookingsData?.location_id,
                            bookingsData?.date,
                            bookingsData?.sub_location_details?.sub_location_id,
                            0
                        )
                    );
                  
                  if(selectedSubLocation?.activity_type === "group-single" || selectedSubLocation?.activity_type === "group-common"){
                      dispatch(
                        fetchGroupsData(
                          bookingsData.sub_location_details.sub_location_id,
                          bookingsData.date
                        )
                      );  
                  };   
                 };
              
                 fetchFeedbackQuestions();
          }, [bookingsData?.date , bookingsData?.sub_location_details , bookingsData?.sub_location_name]);  

       
          useEffect(()=>{
          
              if(selectedSubLocation?.activity_type === "group-single" || selectedSubLocation?.activity_type === "group-common"){
                  if(!groupsData?.loading && groupsData?.groups?.length > 0){
                    let groups = groupsData?.groups;
                    let bookings = bookingsData?.bookings;
                      groups.forEach((group) => {
                        group.booking_details = [];
                        group.booking_ids.forEach((booking_id) => {
                          let booking = bookings.find(
                            (booking) => booking.booking_id === booking_id
                          );
                          if (booking) {
                            group.booking_details.push(booking);
                          }
                        });
                      });
                      // console.log("groups - api : ", groups);
                      setGroupsWithBookings(groups);
                      setSearchResults(groups);
                  }else{
                    setGroupsWithBookings([]);
                    setSearchResults([]);
                  }   
                    
              }else{
                if(!bookingsData?.loading ){
                  // console.log("booking -> Sets : ")
                  if(currentFilter === "All"){
                    setSearchResults(bookingsData.bookings);  
                  }else{
                    const filteredData = bookingsData?.bookings?.filter((b)=>b?.current_status===currentFilter);
                    setSearchResults(filteredData);
                  }
                  
                }
              }
          },[bookingsData?.bookings]);
         

        //* Refresh_Booking */
          const RefreshBookings = () => {
          //  console.log("Refreshhhhhhhhhhhhhhhhhhh--------Booking _-------------function ----------------run");
            dispatch(
              fetchData(
                bookingsData.location_id,
                bookingsData.date,
                bookingsData.sub_location_details.sub_location_id
              )
            );

            if(selectedSubLocation?.activity_type === "group-single" || selectedSubLocation?.activity_type === "group-common"){
              dispatch(
                fetchGroupsData(
                  bookingsData.sub_location_details.sub_location_id,
                  bookingsData.date
                )
              );
            };

              setMultiplebookingPayment(false);
              setBookingEditMode(false);
              SetGroupEditMode(false);
              setSelectedBookings([]);
              setGroupsWithBookings([]);
              if(user?.email === "retargeting_thrill@droame.com"){
                setCurrentFilter("video_uploaded");
              }else{
                setCurrentFilter("All");
              }
          };
          
        //* change Sublocation */
        const handleSelectChange = (event) => {
          const selectedValue = event.target.value;
          const sublocation = subLocationList.find(
            (item) => item.sub_location_name === selectedValue
          );
          if (sublocation !== undefined) {
            dispatch(setSubLocationDetails(sublocation));
            dispatch(setSubLocationActivityType(sublocation?.activity_type));
            dispatch(setSubLocationName(sublocation?.sub_location_name));
            setSelectedSubLocation(sublocation);
          }

        };
        
        //* Search Function */
        const handleSearch=(e)=>{
          let searchTerm = e.target?.value;
          if(selectedSubLocation?.activity_type === "group-single" || selectedSubLocation?.activity_type === "group-common"){
           
              if(searchTerm){
                let groups = [...searchResults];
                
                 const filteredData = groups.filter((group) => {
                  const groupNameMatch = group.group_name.toLowerCase().includes(searchTerm.toLowerCase());
                  const bookingMatch = group.booking_details.some((booking) => {
                      return booking.customer_name.toLowerCase().includes(searchTerm.toLowerCase());
                  });
                  return groupNameMatch || bookingMatch;
              });
               setSearchResults(filteredData);
              }else{
                setSearchResults(groupsWithBookings);
              }
          }else{
              let bookings = [...bookingsData.bookings];
              const filteredData = bookings?.filter((item) =>
                item.customer_name.toLowerCase().includes(searchTerm.toLowerCase())
              );
              console.log(filteredData)
              setSearchResults(filteredData);
            }
          };

        // //* Expand Tab function */
        // const handleTabToggle = (user) => {
        //   if (isExpanded === user) {
        //     // If the same tab is clicked again, close it
        //     setIsExpanded(false);
        //   } else {
        //     // Open the clicked tab and close the currently open tab
        //     setIsExpanded(user);
        //   }
        // }; 
        
        const handleCheckBoxOnChange=(e , g_id)=>{
            if (e.target.checked) {
              setSelectedGroups((prev)=> [...prev , g_id]);
            } else {
              setSelectedGroups(selectedGroups.filter((id) => id !== g_id));
            }
        };
        
        const handleBookingEditMode = (e , g_id)=>{
          //  if(g_id === bookingEditMode){
          //   setSelectedBookings([]);
          //   setBookingEditMode(false);
          //  }else{
          //   setSelectedBookings([]);
          //   setBookingEditMode(g_id);
          //  }
          if(e.target.checked){
            setSelectedBookings([]);
            setBookingEditMode(g_id);
          }else{
            setSelectedBookings([]);
            setBookingEditMode(false);
          }
        }
     
       //* change Filter  */
       const handleFilter=(status)=>{
          setCurrentFilter(status);
        if(status === "All"){
          setSearchResults(bookingsData.bookings);
        }else{
          const filteredData = bookingsData?.bookings?.filter((b)=>b?.current_status===status);
          setSearchResults(filteredData);
        }
        setOpenFilter(false);
       }
      
       const dateFormate=(date)=>{
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
    
         return `${year}-${month}-${day}`;
       }

       //* Get Report Api Call
       const getReportApi=async()=>{
        
          if(!dateRange || dateRange?.length < 1){
            toast.info("Kindly Select Date Range");
          }else{
            setReportLoading(true);
            setReportModalOpen(true);
            try{
              if(bookingsData?.location_id){
                const res = await GetRetargetingReportBookings(bookingsData?.sub_location_details?.sub_location_id, dateFormate(dateRange[0]) , dateFormate(dateRange[1]));
                if(res?.data?.is_success){
                  setReportBookings(res?.data?.bookings)
                  setReportLoading(false);
                }else{
                  toast.error("Something Get Wrong Try After SomeTime");
                  setReportLoading(false);
                }
               
              }
              
            }catch(error){
              console.log('getReport Api :', error);
              setReportLoading(false);
            }
          }
        }
      
     

  return (
    <>

{bookingsData?.location_name === "amber-fort" ? 
              <AmberFortPage bookingsData={bookingsData} RefreshBookings={RefreshBookings}/> :

    <div className='relative mt-2 flex mr-auto ml-auto px-5 xsm:px-2 w-screen max-w-screen gap-5 max-h-[85vh] h-[85vh] xsm:max-h-[120vh] xsm:h-[120vh] items-start justify-between'>
           
          
                   <div className='flex flex-col w-[50%] mmd:w-full gap-5 min-h-full max-h-full '>
                        <div className='flex justify-between sm:flex-wrap items-center  w-full gap-5 '>
                            <div className=' ring-0 w-fit flex items-center justify-center'>
                                <DatePicker
                                selected={ bookingsData?.date ? new Date(bookingsData?.date) : new Date()}
                                className="w-[70%] rounded-md border-none ring-0"
                                onChange={(date) => {
                                    const year = date.getFullYear();
                                    const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1 to get the actual month number
                                    const day = date.getDate();

                                    const formattedDate = `${year}-${month
                                        .toString()
                                        .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
                                        setDate(formattedDate);
                                        dispatch(setCurrentDate(formattedDate));
                                    }}
                                dateFormat="dd-MM-yyyy"
                                isClearable={false}
                                minDate={new Date(new Date().setDate(new Date().getDate() - 15))}
                                placeholderText={bookingsData.date}
                                maxDate={new Date()}
                                />
                            </div>
                           
                            <div className='sm:flex sm:gap-2 justify-between sm:w-full'>
                                <select
                                id="locations"
                                onChange={handleSelectChange}
                                className="custom-select sources rounded-full border focus:ring-0 border-gray-200 bg-white"
                                placeholder="Source Type"
                                disabled={subLocationList?.length < 1 ?  true : false}
                                value={bookingsData?.sub_location_name}
                                >
                                    <option hidden value="default" >Location</option>
                                    {subLocationList?.map((subLocation) => (
                                        <option
                                        key={subLocation.sub_location_id}
                                        value={subLocation.sub_location_name}
                                        >
                                        {subLocation.sub_location_name}
                                        </option>
                                    ))}
                                </select>
                                <LuRefreshCw className='hidden sm:block text-4xl xs:text-2xl cursor-pointer' onClick={RefreshBookings}/>
                            </div>
                        </div>
            
                        {/* // * Search & Filter */}
                          <div className="flex w-full items-center justify-between  sm:flex-row sm:space-y-0 xsm:flex-col xsm:items-start gap-4">
                              <form className="relative flex w-full items-center">
                              <svg className="absolute left-2 block h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                  <circle cx="11" cy="11" r="8" className=""></circle>
                                  <line x1="21" y1="21" x2="16.65" y2="16.65" className=""></line>
                              </svg>
                              <input type="name" name="search"  onChange={handleSearch}
                              className="h-12 w-full border-b-gray-400 bg-transparent py-4 pl-12 text-sm outline-none focus:border-b-2" placeholder="Search by Order ID, Date, Customer" />
                              </form>
                              
                              {selectedSubLocation && (selectedSubLocation?.activity_type !== "group-single" && selectedSubLocation?.activity_type !== "group-common") &&   <div className='relative'>
                                  <button disabled={user?.email === "retargeting_thrill@droame.com" ? true : false} onClick={(e)=>{e.preventDefault()
                                    setOpenFilter((prev)=> !prev)}} type="button" className="relative mr-auto inline-flex gap-2 cursor-pointer items-center rounded-full border border-gray-200 bg-white px-5 py-2 text-center text-sm font-medium text-gray-800 hover:bg-gray-100 focus:shadow sm:mr-0">
                                      {currentFilter!=="All" && <span className="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-500"></span>}
                                      <HiOutlineFilter />
                                    {currentFilter} <span>{(searchResults?.length)}</span>
                                  </button>
                                    {openFilter && 
                                      <div className={`absolute z-[99] top-12 rounded-md overflow-hidden shadow-lg  w-max ${openFilter ? "opacity-1 visible" : "opacity-0 hidden"} duration-200 p-1 bg-gray-100  border border-dimmed text-xs md:text-sm`}>
                                            <div onClick={()=> handleFilter("All")} className={`w-full ${currentFilter === "All" ? "text-webGrenn border-b-2 border-webGrenn" :""} block cursor-pointer hover:bg-white hover:text-link px-3 py-2 rounded-md`}>
                                              All</div>
                                              <div onClick={()=> handleFilter("video_uploaded")} className={`w-full ${currentFilter === "video_uploaded" ? "text-webGrenn border-b-2 border-webGrenn" :""} block cursor-pointer hover:bg-white dark:hover:bg-gray-900 dark:bg-gray-800 hover:text-link px-3 py-2 rounded-md`}>
                                              Uploaded</div>
                                              <div onClick={()=> handleFilter("video_shared")} className={`w-full ${currentFilter === "video_shared" ? "text-webGrenn border-b-2 border-webGrenn" :""}  block cursor-pointer hover:bg-white dark:hover:bg-gray-900 dark:bg-gray-800 hover:text-link px-3 py-2 rounded-md`}>
                                              Shared
                                              </div>
                                              <div onClick={()=> handleFilter("shot_completed")}  className={`w-full ${currentFilter === "shot_completed" ? "text-webGrenn border-b-2 border-webGrenn" :""} block cursor-pointer hover:bg-white dark:hover:bg-gray-900 dark:bg-gray-800 hover:text-link px-3 py-2 rounded-md`}>
                                                In Process 
                                              </div>
                                              <div onClick={()=> handleFilter("PENDING")}  className={`w-full ${currentFilter === "PENDING" ? "text-webGrenn border-b-2 border-webGrenn" :""} block cursor-pointer hover:bg-white dark:hover:bg-gray-900 dark:bg-gray-800 hover:text-link px-3 py-2 rounded-md`}>
                                              Not Captured
                                              </div>
                                            
                                            </div>}
                                  </div> }
                                  
                             <LuRefreshCw className='sm:hidden text-4xl cursor-pointer' onClick={RefreshBookings}/>
                          </div>
                      
                       {/* // * report And Date Range */}
                          {user?.email === "retargeting_thrill@droame.com"  && <div className='flex justify-between flex-wrap'>
                             
                             <DateRangePicker onChange={(ranges)=>setDateRange(ranges)} value={dateRange} className="border-none"/>
 
                             {/* // * Generate Report button */}
                            <div onClick={getReportApi} className="cursor-pointer inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-gray-600 whitespace-no-wrap bg-white border border-gray-200 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:shadow-none">
                                  Report
                              </div>
                          </div>}
           
                      {selectedSubLocation && (selectedSubLocation?.activity_type !== "group-single" && selectedSubLocation?.activity_type !== "group-common") && searchResults?.length > 0 && <div className='flex flex-wrap items-center justify-between'>
                          <button onClick={()=> setMultiplebookingPayment((prev)=> {
                             if(prev){
                               setSelectedBookings([]);
                             };
                             return !prev;
                          })} className="relative inline-block px-4 py-2 font-medium group w-fit">
                              <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                              <span className="absolute inset-0 w-full h-full bg-white border-2 border-black group-hover:bg-black"></span>
                              <span className="relative text-black group-hover:text-white">{multipleBookingPayment ? 'Cancel' : 'Select For Payment'}</span>
                          </button>
                          {multipleBookingPayment && selectedBookings?.length > 1 && <button onClick={()=> setOpenMultipleBookingPaymentModal(true)} className="relative inline-block px-4 py-2 font-medium group w-fit">
                              <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-emerald-500 group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                              <span className="absolute inset-0 w-full h-full bg-white border-2 border-emerald-500 group-hover:bg-emerald-500"></span>
                              <span className="relative text-emerald-600 group-hover:text-white">Pay</span>
                          </button>}
                      </div>}
                      
                      {/* //* Group Merge Buttons   */}
                         {(selectedSubLocation?.activity_type === "group-single" || selectedSubLocation?.activity_type === "group-common") && searchResults?.length > 0 &&  <div className='flex justify-between my-0 mx-3'>
                          <button onClick={()=> {SetGroupEditMode((prev)=>{
                              if(prev){
                                setSelectedGroups([]);
                              };
                              return !prev;
                            })
                          }} className={`${groupEditMode ? "text-red-500 border-red-500 hover:bg-red-500 active:bg-red-600 " : "text-blue-500 border-blue-500 hover:bg-blue-500 active:bg-blue-600"}  bg-transparent border border-solid   hover:text-white font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`} type="button">{groupEditMode ? "Cancel" : "Edit"}</button>
                          {groupEditMode && selectedGroups.length > 1 && <button onClick={()=> setShowGrpMergeModal(true)} className="text-emerald-500 bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Merge</button>}

                          </div> }

            <div className="relative bg-gray-50 max-h-full  min-h-full h-full w-full overflow-y-auto" >
                <div className="px-2 py-0 min-h-full max-h-full h-full  w-full ">
             
                   <div className="relative mt-3 rounded-xl px-0 shadow border-2 max-h-full min-h-[60vh] w-full ">
                    {(selectedSubLocation?.activity_type === "group-single" || selectedSubLocation?.activity_type === "group-common") ? 
                     bookingsData?.loading || groupsData?.loading ? 
                            <div className='w-full flex items-center justify-center h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                            <SimpleLogoLoader logoWidth="80px"/> </div> :
                          <>
                           
                          {showGrpMergeModal &&  <GrpMerModal isOpen={showGrpMergeModal} onCloseGrpModal={()=> setShowGrpMergeModal(false)} groups={selectedGroups} SetGroupEditMode={SetGroupEditMode} RefreshBookings={RefreshBookings} />}
                          {/* //* Group Booking Table  */}
                        
                            
                            {searchResults?.length > 0 ? (searchResults?.map((group , idx)=>(
                              <Accordian key={idx} id={`${idx}`} className="max-w-lg">
                                <AccordianItem key={group?.group_id}  value={group?.group_id} trigger={group?.group_name} group={group} handleCheckBoxOnChange={(e)=>{handleCheckBoxOnChange(e,group?.group_id)}} groupEditMode={groupEditMode} RefreshBookings={()=> RefreshBookings()}>
                                  <>
                                  <div className='flex justify-between'> <Switch checked={bookingEditMode === group?.group_id} onChange={(e)=> handleBookingEditMode(e,group?.group_id)} inputProps={{ 'aria-label': 'controlled' }} /> 
                                      {(bookingEditMode === group?.group_id) && selectedBookings?.length > 0 &&  <button >
                                              <MdOutlineDeleteForever onClick={()=>{setDeleteBookingConfirmationModal(true)}} className="text-3xl text-red-400" />
                                            </button>}
                                  </div>
                                  {openDeleteBookingConfirmationModal && <DeleteBookingsConfirmationModal modalOpen={openDeleteBookingConfirmationModal} onCloseModal={()=>{setDeleteBookingConfirmationModal(false)} }
                                    selectedBookings={selectedBookings} group_id={group?.group_id} RefreshBookings={RefreshBookings} setSelectedBookings={setSelectedBookings} setBookingEditMode={setBookingEditMode} /> }

                                  {group?.booking_details?.map((booking)=>(
                                  
                                        <table key={booking?.booking_id} className="w-full border-collapse border-spacing-y-2 border-spacing-x-2 bg-[#FFFAF4] mb-2">
                                              <TableRowComp key={booking?.booking_id} {...booking} booking={booking} isExpanded={isExpanded} setIsExpanded={setIsExpanded} bookingEditMode={bookingEditMode} setSelectedBookings={setSelectedBookings} 
                                                user={user}
                                                isBookingSelected={selectedBookings?.some(
                                                (selected) => selected.booking_id === booking.booking_id
                                              )} 
                                              g_id= {group?.group_id}
                                              />
                                          </table>
                                    
                                  ))}
                                  </>
                                </AccordianItem>
                            </Accordian>
                            ))) : 
                            (<div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center text-center'>
                                      <FcDeleteDatabase className='text-7xl'/>
                                      <p>No Groups Found for the Selected Criteria</p>
                            </div>)}

                         

                          </> 
                            :
                        //  * Indivdual Booking Table */}
                        <table className="w-full border-collapse border-spacing-y-2 border-spacing-x-2 ">
                            <thead className="xsm:hidden border-btable-header-group">
                                <tr className="">
                                <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 px-3">Customer</td>
                                    <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 px-3">Status</td>
                                    {user?.email === "retargeting_thrill@droame.com" && <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 px-3">Phone No.</td>}
                                    <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 px-3">Video</td>
                                  
                                </tr>
                            </thead>  
                            {bookingsData?.loading ? 
                              <div className='w-full flex items-center justify-center h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                              <SimpleLogoLoader logoWidth="80px"/> </div>:
                            <tbody className=" bg-white border-gray-300">
                            
                                {searchResults?.length > 0 ? searchResults?.map((booking)=>(
                                    
                                      <TableRowComp key={booking?.booking_id} {...booking} booking={booking} isExpanded={isExpanded} setIsExpanded={setIsExpanded} bookingEditMode={bookingEditMode} multipleBookingPayment={multipleBookingPayment}
                                      setSelectedBookings={setSelectedBookings}
                                      user={user}
                                      isBookingSelected={selectedBookings?.some(
                                        (selected) => selected.booking_id === booking.booking_id
                                      )} 
                                      RefreshBookings={RefreshBookings}
                                      selectedSubLocation={selectedSubLocation}
                                    />
                                )): 
                                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center text-center'>
                                  <FcDeleteDatabase className='text-7xl'/>
                                  <p>No Bookings Found for the Selected Criteria</p>
                                </div>} 
                              
                                {
                                mobileScreen ? openMultipleBookingPaymetModal && <PaymentCheckoutPage modalOpen={openMultipleBookingPaymetModal} onCloseModal = {()=>{setOpenMultipleBookingPaymentModal(false)
                                   setSelectedBookings([]);
                                  setMultiplebookingPayment(false);

                                  }} booking={selectedBookings}  groupPaymet={false} RefreshBookings={RefreshBookings}/>
                                  :
                                  openMultipleBookingPaymetModal && <CustomModal modalOpen={openMultipleBookingPaymetModal} onCloseModal = {()=>{setOpenMultipleBookingPaymentModal(false)
                                    setSelectedBookings([]);
                                    setMultiplebookingPayment(false);
                          
                                  }} booking={selectedBookings}  groupPaymet={false} RefreshBookings={RefreshBookings} />
                                }
                              
                            </tbody>}
                                
                        </table>
                     
                       }
  
                    </div>  
                    
                </div>
            </div>
                  </div>
                

                 
            
            {reportModalOpen && <ReportModal modalOpen={reportModalOpen} onCloseModal={()=>setReportModalOpen(false)} reportBookings={reportBookings} reportLoading={reportLoading}/>}
            {!mobileScreen &&  <div className='flex mmd:hidden h-full overflow-hidden relative min-w-[45%] items-start justify-center gap-5'>
                <VideoPlayerComp setShowFullPortrait={setShowFullPortrait}/>
            </div> }
        
     
            {mobileScreen &&  <Modal isOpen={customerData?.video_url ? true : false} onRequestClose={()=>{dispatch(closeVideo())}}  contentLabel="Video Modal" style={customStyles} ariaHideApp={false}>
                      <VideoPlayerComp />
            </Modal>} 
          
      
    </div>
   
    }  </>
  )
}

export default LocationDataComp