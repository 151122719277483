import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import SimpleLogoLoader from './logoloader/SimpleLogoLoader';
import PhoneInput from 'react-phone-number-input'
import { createAmberBooking, getAmberFortShots, getAmberFortSubLocations, getCustomerDetailsAmberFort, updateAmberBooking, updateCustomerDetails } from '../services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IoClose } from "react-icons/io5";

const AmberFortEditFormModal = ({modalOpen , onCloseModal , editData , RefreshBookings }) => {
    const [loading , setLoading]=useState(false);
    const bookingsData = useSelector((store) => store.bookings);
    const [formData , setFormData]=useState(editData);

   
    const customStyles = {
        content: {
           zIndex:'20',
           padding:'10px',
           margin:'0px 0px',
           boxShadow: '0px 0px 41px 5px rgba(0,0,0,0.1)',
           borderRadius:'12px',
           top: '50%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
      
        },
      };
    
    useEffect(()=>{
        setFormData(editData);
    },[editData])

    const handleFormChange=(e , data)=>{
        const name = e.target.name;
        const value = e.target.value;
        setFormData((prev)=>({...prev , [name]:value}));
    };
    
    const handlePhoneNumberChange=(number)=>{
        setFormData((prev)=>({...prev ,phone_number:number }))
    }
   
    const updateBooking=async()=>{
        setLoading(true);
        try{
            const res = await updateAmberBooking(formData?.customer_id , formData?.booking_id , {action:"booking_updated" , ...formData});
            console.log("res : " , res);
            if(res?.data?.is_success){
                toast.success(res?.data?.message);
                RefreshBookings();
                onCloseModal();
            }else{
                toast.info(res?.data?.message);
            }
            console.log("create-bookings : ", res);
        }catch(err){
            toast.error("Something went wrong kindly try after some time");
            setLoading(false);
        }finally{
            setLoading(false);
        }
    }

    const handleSubmit= async (e)=>{
        e.preventDefault();
        updateBooking();
    };

 

   console.log("formdata : " , formData);
  return (
    <Modal isOpen={modalOpen} onRequestClose={()=>{onCloseModal(false)}}  contentLabel="Feedback Modal" style={customStyles} ariaHideApp={false}>
        <div className={`m-0 flex flex-col gap-5 items-start rounded-md  px-8 py-10 text-gray-800 relative mx-auto z-50 w-auto h-auto `}>
        <div className='relative flex flex-col justify-center items-center my-5 h-full'>
     
{loading && <div className='z-20 w-screen h-full flex items-center justify-center bg-[#ffffffcc] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
    <SimpleLogoLoader logoWidth="100px"/> </div> }

      <h1 className='my-2 text-2xl bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text'>Booking Creating Form</h1>
      
    <form action="" onSubmit={handleSubmit} className='flex flex-wrap w-full shadow-2xl rounded-xl p-5'>
      <div className='w-full flex justify-end items-end'><IoClose onClick={onCloseModal} className='text-4xl text-red-500 cursor-pointer'/></div>
        <div className="m-5 w-full">
            <label htmlFor="price" className="mb-2 block text-sm font-medium">Name</label>
            <div className="relative">
                <input type="text" id="customer_name" name="customer_name" onChange={handleFormChange} value={formData?.customer_name ? formData?.customer_name : ""} className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-9 pr-16 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Name" required/>
            </div>
        </div>

       
        <div className="m-5 w-full">
            <label htmlFor="phone" className="mb-2 block text-sm font-medium">Phone number</label>
            <div className="relative">
                <PhoneInput
                    className="rounded-full"
                    international
                    placeholder="+91 00000-00000"
                    defaultCountry="IN"
                    value={formData?.customer_phone_number ? formData?.customer_phone_number : ""}
                    onChange={handlePhoneNumberChange}/>
                    
            </div>
        </div>

        <div className='w-full'>
            <button type='submit' className="mt-4 rounded-full bg-blue-800 px-10 py-2 font-semibold text-white">Edit Booking</button>
        </div>
        
    </form>
 
</div>      
        </div>
  </Modal>
  )
}

export default AmberFortEditFormModal