import React from 'react'
import Modal from "react-modal";

import CicleLoader from './CicleLoader';
import { QRCode } from 'react-qrcode-logo';
import assets from '../assets';
import { IoClose } from "react-icons/io5";
const AmberFortFormModal = ({modalOpen , onCloseModal , resObj }) => {
 
    const customStyles = {
        content: {
           zIndex:'20',
           padding:'10px',
           margin:'0px 0px',
           boxShadow: '0px 0px 41px 5px rgba(0,0,0,0.1)',
           borderRadius:'12px',
           top: '50%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
      
        },
      };


        
    

  return (
      <>
        <Modal isOpen={modalOpen} onRequestClose={()=>{onCloseModal(false)}}  contentLabel="Feedback Modal" style={customStyles} ariaHideApp={false}>
        <div className={` m-0 flex flex-col gap-5 items-start rounded-md  px-8 py-10 text-gray-800 relative mx-auto z-50 w-auto h-auto `}>
        <div className='w-full flex justify-end items-end'><IoClose onClick={onCloseModal} className='text-4xl text-red-500 cursor-pointer'/></div>
          {!resObj && <CicleLoader/> }
          {resObj && 
            <div className="flex flex-col items-center w-full justify-center">
               <h3>{resObj?.customer_name}</h3>
            <QRCode 
                value={resObj?.qr_link}
                size={250}
                ecLevel={"M"}
                quietZone={50}
                logoImage={assets?.droame_logo_green}
                logoWidth={120}
                logoOpacity={0.5}
                emoveQrCodeBehindLogo={false}
                qrStyle="dots"
                eyeRadius={10}
                eyeColor={"#00B2B2"}
                // id={booking_id}
                style={{ height: "auto", width:"100%" }}
                // bgColor={"#00B2B2"}
                fgColor={"#00B2B2"}
                // logoPadding={0}
                                    />
            </div>
            } 
                        
        </div>
      </Modal>
      </>
  )
}

export default AmberFortFormModal;