import React, { useEffect, useState } from 'react'
import { deleteAmberBooking, getAmberFortSubLocations, getBookingsAmberFort, updateAmberBooking } from '../services/api';
import { LuRefreshCw } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
import { fetchBookingsAmberFort, setSubLocationActivityType, setSubLocationDetails, setSubLocationName } from '../actions/BookingAction';
import { BsQrCodeScan } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import AmberFortFormModal from '../components/AmberFortFormModal';
import { Link } from 'react-router-dom';
import SimpleLogoLoader from '../components/logoloader/SimpleLogoLoader';
import assets from '../assets';
import AmberFortEditFormModal from '../components/AmberFortEditFormModal';
import { toast } from 'react-toastify';

const AmberFortPage = ({bookingsData}) => {
    const dispatch = useDispatch();
    const [subLocationList, setSubLocationList] = useState([]);
    const [selectedSubLocation, setSelectedSubLocation] = useState(bookingsData?.sub_location_details);
    const [amberFortModalOpen ,setAmberFortModalOpen]=useState(false);
    const [loading , setLoading]=useState(false);
    const [qrModalOpen , setQrModalOpen]=useState(false);
    const [editModalOpen , setEditModalOpen]=useState(false);
    const [selectedBooking ,setSelectedBooking]=useState(undefined);
    const [editData , setEditData]=useState({});

    const getSubLocations=async()=>{
        setLoading(true);
        try {
            const response = await getAmberFortSubLocations();
            setSubLocationList(response?.data?.sub_locations);
            setLoading(false);
          } catch (error) {
            console.error("Error fetching feedback questions:", error);
            setLoading(false);
          }     
    }

    useEffect(()=>{
        getSubLocations();
        // getAmberFortBookings();
    },[]);

  
 //* change Sublocation */
        const handleSelectChange = (event) => {
            setLoading(true);
            const selectedValue = event.target.value;
            const sublocation = subLocationList.find(
              (item) => item.sub_location_name === selectedValue);
              
            if (sublocation !== undefined) {
              dispatch(setSubLocationDetails(sublocation));
              dispatch(setSubLocationActivityType(sublocation?.activity_type));
              dispatch(setSubLocationName(sublocation?.sub_location_name));
              setSelectedSubLocation(sublocation);
                  dispatch(
                    fetchBookingsAmberFort(
                        sublocation?.location_id,
                        sublocation?.sub_location_id,
                    )
                );
                setLoading(false);
            }
  
          };
     
     //* Refresh Amber Fort bookings */
     const refreshAmberFortBookings =()=>{
      if(selectedSubLocation)
          dispatch(
            fetchBookingsAmberFort(
                selectedSubLocation?.location_id,
                selectedSubLocation?.sub_location_id,
            )
        );
     }     
     
    const handleSelectBooking=(booking)=>{
        setSelectedBooking(booking);
        setQrModalOpen(true);
    }
    
     //* Delete Booking */
    const handleDeletebooking=async(data)=>{
    
      setLoading(true);
      const deleteBooking = {
        action:"shot_deleted",
        video_timestamp:"0",
        booking_id:data?.booking_id,
        locaation_id:data.location_id,
        sub_location_id:data.sub_location_id,
        current_status: "deleted"
      }
     
      try {
        const response = await deleteAmberBooking(data?.customer_id , data?.booking_id ,deleteBooking);
        toast.success(response?.data?.message);
        console.log("response-delete : " , response);
        refreshAmberFortBookings();
      } catch (error) {
        console.error("Something went wrong delete after sometime:", error);
        toast.error("Something went wrong delete after sometime");
        
      }finally{
        setLoading(false);
      }  
    }
   

    function formatDate(dateData) {
      if(!dateData) return 'No date found'
      const date = new Date(dateData);
      // Get the day, month, and year
      const day = String(date.getDate()).padStart(2, '0');    // Ensure 2 digits for day
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits for month (months are 0-indexed)
      const year = date.getFullYear();                         // Get the full year
      
      // Return the formatted date as day-month-year
      return `${day}-${month}-${year}`;
    }
  

   console.log("bookingsData==Amber fort :" , selectedBooking);

  return (
    <>
    {(loading || bookingsData?.loading) && <div className='z-20 w-screen h-full flex items-center justify-center bg-[#ffffffcc] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
        <SimpleLogoLoader logoWidth="100px"/> </div> }

    <div className='container  mx-auto mt-10'>
        <div className="mx-auto max-w-screen-lg px-4 py-8 sm:px-8"> 
            <div className="flex flex-wrap items-center justify-between pb-6 w-full gap-4">
               
              <div className=''>
                                <select
                                id="locations"
                                onChange={handleSelectChange}
                                className="custom-select sources rounded-full border focus:ring-0 border-gray-200 bg-white"
                                placeholder="Source Type"
                                disabled={subLocationList?.length < 1 ?  true : false}
                                value={bookingsData?.sub_location_name}
                                >
                                    <option hidden value="default" >Location</option>
                                    {subLocationList?.map((subLocation) => (
                                        <option
                                        key={subLocation.sub_location_id}
                                        value={subLocation.sub_location_name}
                                        >
                                        {subLocation.sub_location_name}
                                        </option>
                                    ))}
                                </select>
                                {/* <LuRefreshCw className='hidden sm:block text-4xl xs:text-2xl cursor-pointer' /> */}
              </div>

                <div className="flex items-center justify-between">
                <div className="">
                    <Link to={`/${bookingsData?.location_name}/customer-video/createbooking`} className="flex items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white focus:outline-none focus:ring hover:bg-blue-700">
                       Create Booking
                    </Link>
                </div>
                </div>
            </div>
            {bookingsData?.bookings?.length < 1 ?

              <div className="flex justify-center items-center flex-col text-center my-auto w-[30%]  md:w-[65%] mmd:w-[80%] mx-auto"> {/* Example Tailwind classes for centering */}
              <div className='w-[100%]'><img src={assets?.notFonund_drone} alt="notfound_drone" className='w-full object-cover'/></div> 
               <p>Please select a booking to start the video experience. </p>
             </div>
             :
            <div className="overflow-y-hidden rounded-lg border">
                <div className="overflow-x-auto">
                <table className="w-full">
                    <thead className='sm:hidden'>
                    <tr className="bg-blue-600 text-left text-xs font-semibold uppercase tracking-widest text-white">
                        <th className="px-5 py-3">No.</th>
                        <th className="px-5 py-3">Name</th>
                        <th className="px-5 py-3">Phone No.</th>
                        <th className="px-5 py-3">Shot Name</th>
                        <th className="px-5 py-3">Created at</th>
                        <th className="px-5 py-3">Qr</th>
                        <th className="px-5 py-3">Edit</th>
                        <th className="px-5 py-3">Delete</th>
                    </tr>
                    </thead>
                    <tbody className="text-gray-500">
                        {bookingsData?.bookings?.map((booking , idx)=>(
                          
                          <tr key={idx}>
                            <td className='hidden sm:visible sm:flex flex-col gap-3 justify-between border-b-2 my-3 px-2'>
                                <div className='flex gap-2 justify-between'>
                                   <p>{idx+1}</p>
                                   <p>{booking?.customer_name}</p>
                                   <p>{booking?.customer_phone_number}</p>
                                </div>
                                <div className='flex flex-wrap gap-2 justify-between '>
                                    <p>{formatDate(booking?.created_at ? booking?.created_at : booking?.updated_at)}</p>
                                    <BsQrCodeScan className='text-xl' onClick={()=>handleSelectBooking(booking)}/>
                                    <CiEdit className='text-2xl' onClick={()=>{setEditModalOpen(true);
                                     setEditData(booking);
                                    }}/>
                                      <MdDeleteOutline className='text-xl' onClick={()=>{
                                        handleDeletebooking(booking);
                                    }}/>
                                </div>
                            </td>

                            <td className="sm:hidden border-b border-gray-200 bg-white px-5 py-5 text-sm">
                              <p className="whitespace-no-wrap">{idx+1}</p>
                            </td>
                            <td className="sm:hidden border-b border-gray-200 bg-white px-5 py-5 text-sm">
                            <div className="flex  items-center">
                                <div className="ml-3">
                                <p className="whitespace-no-wrap">{booking?.customer_name}</p>
                                </div>
                            </div>
                            </td>

                            <td className="sm:hidden border-b border-gray-200 bg-white px-5 py-5 text-sm">
                                <p className="whitespace-no-wrap">{booking?.customer_phone_number}</p>
                            </td>
                            <td className="sm:hidden border-b border-gray-200 bg-white px-5 py-5 text-sm">
                                <p className="whitespace-no-wrap">{booking?.shot_name}</p>
                            </td>
                            
                            <td className="sm:hidden border-b border-gray-200 bg-white px-5 py-5 text-sm">
                                <p className="whitespace-no-wrap">{formatDate(booking?.created_at ? booking?.created_at : booking?.updated_at)}</p>
                            </td>
    
                            <td className="sm:hidden border-b border-gray-200 bg-white px-5 py-5 text-sm cursor-pointer" onClick={()=>handleSelectBooking(booking)}>
                                <BsQrCodeScan className='text-xl' />
                            </td>

                            <td className="sm:hidden border-b border-gray-200 bg-white px-5 py-5 text-sm cursor-pointer">
                                <CiEdit className='text-2xl' onClick={()=>{setEditModalOpen(true);
                                     setEditData(booking);
                                }}/>
                            </td>

                            <td className=" sm:hidden border-b border-gray-200 bg-white px-5 py-5 text-sm cursor-pointer" >
                                <MdDeleteOutline className='text-xl' onClick={()=>{
                                    handleDeletebooking(booking);
                                }}/>
                            </td>
                      </tr>

                        ))}
                   
                   
                    </tbody>
                </table>
                </div>
                <div className="flex flex-col items-center border-t bg-white px-5 py-5 sm:flex-row sm:justify-between">
                {/* <span className="text-xs text-gray-600 sm:text-sm"> Showing 1 to 5 of 12 Entries </span>
                <div className="mt-2 inline-flex sm:mt-0">
                    <button className="mr-2 h-12 w-12 rounded-full border text-sm font-semibold text-gray-600 transition duration-150 hover:bg-gray-100">Prev</button>
                    <button className="h-12 w-12 rounded-full border text-sm font-semibold text-gray-600 transition duration-150 hover:bg-gray-100">Next</button>
                </div> */}
                </div>
            </div>
           }
        </div>
    </div>

             <AmberFortFormModal modalOpen={qrModalOpen} onCloseModal={()=>setQrModalOpen(false)} resObj={selectedBooking}/>
             <AmberFortEditFormModal modalOpen={editModalOpen} onCloseModal={()=>setEditModalOpen(false)} editData={editData} RefreshBookings={refreshAmberFortBookings}/>
    </>
  )
}

export default AmberFortPage