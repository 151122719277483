import React, { useEffect, useState } from 'react'
import { createAmberBooking, getAmberFortShots, getAmberFortSubLocations, getCustomerDetailsAmberFort } from '../services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SimpleLogoLoader from '../components/logoloader/SimpleLogoLoader';
import AmberFortFormModal from '../components/AmberFortFormModal';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
const AmberFortBookingPage = () => {
    
    const bookingsData = useSelector((store) => store.bookings);
    const [subLocationList, setSubLocationList] = useState([]);
    const [selectedSublocation , setSelectedSublocation]=useState();
    const [shots , setShots]=useState([]);
    const [filteredShots , setFilteredShots]=useState([]);
    const [openSublocation , setOpenSublocation]=useState(false);
    const [formData , setFormData]=useState({location_id:bookingsData?.location_id , location_name:bookingsData?.location_name , current_status:"PENDING" , transaction_id:"null" , path_ids:"" , video_orientation:"landscape" , ticket_id:"" , qr_link:"",transaction_details:{mode:"", transaction_id:"null" , payment_status:"Completed" , amount:0 , sub_location_id:"" , discount:0 , final_amount:0,no_of_customers:0 , nationality:""}});
    const [defaultFormData , setDefaultFormData]=useState({location_id:bookingsData?.location_id , location_name:bookingsData?.location_name , current_status:"PENDING" , transaction_id:"null" , path_ids:"" , video_orientation:"landscape" , ticket_id:"" , qr_link:"",transaction_details:{mode:"", transaction_id:"null" , payment_status:"Completed" , amount:0 , sub_location_id:"" , discount:0 , final_amount:0,no_of_customers:0 , nationality:""}});
    const [selectedShot , setSelectedShot]=useState(undefined);
    const [loading , setLoading]=useState(false);
    const [amberQrModal ,setAmberQrModal]=useState(false);
    const [resObj , setResObj]=useState(undefined);
    const [amount , setAmount]=useState(0);
    const [openCombo , setOpenCombo]=useState(false);
    const [comboBookings , setComboBooings]=useState(false);
    const bulkBookingsList = ["Chandani , Jasmandir , Domb"]
    const paymentModeArr = ["qr" , "cash" , "card" , "foc"];
    const comboSubLocationsObj=[{  "sub_location_id": "0052b816-5f6a-4957-b33c-79c65d2d54cf", "sub_location_name": "Chandani", "path_ids": [
        "65afd5fe-aa22-43eb-ba54-bdec9c523f6c-amer"
    ], "shot_id": "751e20cf-aba9-4564-978d-28bb8d6ac640","amount": 499,"shot_mode": "automated","shot_name": "Chandani Shot", "price": {
        "indian": {
            "first": 1333,
            "second": 1333
        },
        "foreigner": {
            "first": 2000,
            "second": 2000
        }
    }},
        { "sub_location_id": "acd4d974-b04c-4a2f-a927-7b86d14c024c", "sub_location_name": "Domb","amount": 499,"shot_id": "2ebefb27-e77e-4378-a5e5-78d6ce9deaf8","shot_mode": "automated",  "shot_name": "Domb Shot",  "path_ids": [
            "65afd5fe-aa22-43eb-ba54-bdec9c523f6c-amer"
        ], "price": {
            "indian": {
                "first": 1333,
                "second": 1333
            },
            "foreigner": {
                "first": 2000,
                "second": 2000
            }
        }},
        { "sub_location_id": "b9aba51a-885b-4599-bab0-92a1436d4375","amount": 499,"sub_location_name": "Jas Mandir","shot_name": "Jas Mandir Shot","shot_id": "c0e9b11b-2ca0-4f89-b04f-bd24fbd2a9a0", "shot_mode": "automated", "path_ids": [
            "65afd5fe-aa22-43eb-ba54-bdec9c523f6c-amer"
        ], "price": {
            "indian": {
                "first": 1333,
                "second": 1333
            },
            "foreigner": {
                "first": 2000,
                "second": 2000
            }
        }}];
    

    const getSubLocations=async()=>{
        try {
            const response = await getAmberFortSubLocations();
            setSubLocationList(response?.data?.sub_locations);
          } catch (error) {
            console.error("Error fetching feedback questions:", error);
          }     
    }

    const getShots=async()=>{
        try {
            const response = await getAmberFortShots();
            console.log("response :", response);
            setShots(response?.data?.shots);
          } catch (error) {
            console.error("Error fetching Shots:", error);
          }     
    }

    useEffect(()=>{
        getSubLocations();
        getShots();
    },[]);

    useEffect(()=>{
      setFormData((prev)=>({...prev , location_id:bookingsData?.location_id , location_name:bookingsData?.location_name}));
    },[bookingsData])

    console.log(subLocationList);
    
    const handleGetShots=async(loc)=>{
        setComboBooings(false);
        setSelectedSublocation(loc);
        setFormData((prev)=>({...prev , sub_location_id:loc?.sub_location_id , sub_location_name:loc?.sub_location_name , transaction_details:{...prev?.transaction_details , sub_location_id:loc?.sub_location_id,amount:0,final_amount:0}}));
        const fil = shots?.filter((val)=>val?.sub_location_id === loc?.sub_location_id);
        setFilteredShots(fil);
        setOpenSublocation(false);
    }

    const handleFormChange=(e , data)=>{
        const name = e.target.name;
        const value = e.target.value;
        if(e.target.name === "shots"){
            if(e.target.checked){
                console.log("shots-data : " , data);
                setSelectedShot(data);
               const shotsObj = {
                   amount:data?.amount,
                   shot_id:data?.shot_id,
                   shot_name:data?.shot_name,
                   shot_mode:data?.shot_mode,
                   path_ids:data?.path_ids,
               }
               const txnObj = {
                    amount:data?.amount,
                    final_amount:data?.amount
               }
               if(formData?.transaction_details?.nationality === "indian"){
                // setAmount(Number(data?.price_per_customer?.indian ?? 0) + Number(data?.amount ?? 0));
                setAmount(Number(data?.price?.indian?.first));
                if (txnObj) {
                    txnObj.amount = data?.price?.indian?.first;
                    txnObj.final_amount = data?.price?.indian?.first;
                }
              }else if(formData?.transaction_details?.nationality === "foreigner"){
                // setAmount(Number(data?.price_per_customer?.foreigner ?? 0) + Number(data?.amount ?? 0));
                setAmount(Number(data?.price?.foreigner?.first));
                if (txnObj) {
                    txnObj.amount = data?.price?.foreigner?.first;
                    txnObj.final_amount = data?.price?.foreigner?.first;
                }
               }else{
                setAmount(data?.price?.indian?.first);
                if (txnObj) {
                    txnObj.amount = data?.price?.indian?.first;
                    txnObj.final_amount = data?.price?.indian?.first;
                }
               }
               
                txnObj.final_amount = formData?.transaction_details?.mode === "foc" ? 0 : txnObj?.final_amount;
                setFormData((prev)=> ({...prev, ...shotsObj , transaction_details:{...prev?.transaction_details , ...txnObj}}));
            }
        }else if(name === "nationality" && e.target.checked){
            if(e.target.id === "indian"){
                // setAmount(Number(selectedShot?.price_per_customer?.indian ?? 0) + Number(selectedShot?.amount ?? 0));
                if(comboBookings){
                    setAmount(4000);
                }else if(selectedShot){
                    setAmount(Number(selectedShot?.price?.indian?.first));
                    setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details , amount:selectedShot?.price?.indian?.first,final_amount:prev?.transaction_details?.mode === "foc" ? 0 : selectedShot?.price?.indian?.first , nationality:e.target.id}}));
                }
              }else if(e.target.id === "foreigner"){
                // setAmount(Number(selectedShot?.price_per_customer?.foreigner ?? 0) + Number(selectedShot?.amount ?? 0));
                if(comboBookings){
                    setAmount(6000);
                }else if(selectedShot){
                    setAmount(Number(selectedShot?.price?.foreigner?.first));
                    setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details , amount:selectedShot?.price?.foreigner?.first , final_amount:prev?.transaction_details?.mode === "foc" ? 0 : selectedShot?.price?.foreigner?.first , nationality:e.target.id}}));
                }
               } 
               setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details, nationality:e.target.id}}));
        }else if(name === "paymentMode"){
            if(e.target.id === "foc"){
                setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details , mode:e.target.id , final_amount:0}}));
            }else{
                setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details , mode:e.target.id}}));
            }
        }else if(name === "custom_price"){
            setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details , amount:value,final_amount:prev?.transaction_details?.mode === "foc" ? 0 : value}}));
        }
        else{
            if(name === "no_of_customers"){
                setFormData((prev)=> ({...prev , transaction_details:{...prev?.transaction_details , no_of_customers:value}}));
            }else{
                setFormData((prev)=>({...prev , [name]:value}))
            }
        }

    };
    
    const handlePhoneNumberChange=(number)=>{
        setFormData((prev)=>({...prev ,phone_number:number }))
    }
   
    const createBookingApi=async(c_id)=>{
        try{
            const res = await createAmberBooking(c_id ,formData);
            if(res?.data?.is_success){
                setResObj(res?.data?.booking)
                setAmberQrModal(true);
                toast.success(res?.data?.message);
                setFormData(defaultFormData);
                setFilteredShots([]);
                setLoading(false);
            }else{
                toast.info(res?.data?.message);
                setLoading(false);
            }
            console.log("create-bookings : ", res);
        }catch(err){
            toast.error("Something went wrong kindly try after some time");
            setLoading(false);
        }
    }

      const comboCreateBookingApi=async(c_id , updateData , idx)=>{
            try{
                const res = await createAmberBooking(c_id ,updateData);
                if(res?.data?.is_success){
                    toast.success(`${idx+1}-Booking--${res?.data?.message}`);
                    if(idx === 2){
                        setResObj(res?.data?.booking)
                        setAmberQrModal(true);
                        setFormData(defaultFormData);
                        setFilteredShots([]);
                        setLoading(false);
                        setComboBooings(false);
                        
                    }
                    
                }else{
                    toast.info(res?.data?.message);
                    if(idx === 2){
                    setLoading(false);
                    }
                }
                console.log("create-bookings : ", res);
            }catch(err){
                toast.error("Something went wrong kindly try after some time");
                setLoading(false);
            };
        }

    const combobookingsCreateBookings=async(c_id)=>{
        let customAmount = 0;
       if(formData?.transaction_details?.amount && formData?.transaction_details?.final_amount ){
           customAmount =  Math.round(amount / 3);
       }

        comboSubLocationsObj?.forEach((val , idx)=>{
          let updateBookingObj={};
          if(customAmount > 0){
            updateBookingObj = {...formData ,sub_location_id:val?.sub_location_id ,sub_location_name:val?.sub_location_name , 
                amount:val?.amount , shot_id:val?.shot_id , shot_name:val?.shot_name , shot_mode:val.shot_mode , path_ids:val?.path_ids, 
                transaction_details:{...formData?.transaction_details , sub_location_id:val?.sub_location_id , amount:customAmount , final_amount:customAmount}};
          }else if(formData?.transaction_details?.mode === 'foc'){
                if(formData?.transaction_details?.nationality === "indian"){
                    updateBookingObj = {...formData ,sub_location_id:val?.sub_location_id ,sub_location_name:val?.sub_location_name , 
                        amount:val?.amount , shot_id:val?.shot_id , shot_name:val?.shot_name , shot_mode:val.shot_mode , path_ids:val?.path_ids, 
                        transaction_details:{...formData?.transaction_details , sub_location_id:val?.sub_location_id , amount:val?.price?.indian?.first , final_amount:0}};
                }else if(formData?.transaction_details?.nationality === "foreigner"){
                    updateBookingObj = {...formData ,sub_location_id:val?.sub_location_id ,sub_location_name:val?.sub_location_name , 
                        amount:val?.amount , shot_id:val?.shot_id , shot_name:val?.shot_name , shot_mode:val.shot_mode , path_ids:val?.path_ids, 
                        transaction_details:{...formData?.transaction_details , sub_location_id:val?.sub_location_id , amount:val?.price?.foreigner?.first , final_amount:0}};
                }
            }else{
                if(formData?.transaction_details?.nationality === "indian"){
                    updateBookingObj = {...formData ,sub_location_id:val?.sub_location_id ,sub_location_name:val?.sub_location_name , 
                        amount:val?.amount , shot_id:val?.shot_id , shot_name:val?.shot_name , shot_mode:val.shot_mode , path_ids:val?.path_ids, 
                        transaction_details:{...formData?.transaction_details , sub_location_id:val?.sub_location_id , amount:val?.price?.indian?.first , final_amount:val?.price?.indian?.first}};
                }else if(formData?.transaction_details?.nationality === "foreigner"){
                    updateBookingObj = {...formData ,sub_location_id:val?.sub_location_id ,sub_location_name:val?.sub_location_name , 
                        amount:val?.amount , shot_id:val?.shot_id , shot_name:val?.shot_name , shot_mode:val.shot_mode , path_ids:val?.path_ids, 
                        transaction_details:{...formData?.transaction_details , sub_location_id:val?.sub_location_id , amount:val?.price?.foreigner?.first , final_amount:val?.price?.foreigner?.first}};
                }
            } 
           console.log("updateBookingObj -- >",updateBookingObj)
           comboCreateBookingApi(c_id,updateBookingObj,idx);
            
        })
    }

    const getCustomerDetailsApiCall=async()=>{
        if(!formData?.customer_name || !formData?.phone_number){
            toast.info("Kindly Must Be Filled Name and Phone number");
            return ;
        }
        setLoading(true);
      try{
            const res = await getCustomerDetailsAmberFort({customer_name :formData?.customer_name ,phone_number:formData?.phone_number});
            if(res?.data?.is_success){
                if(formData?.transaction_details?.nationality && formData?.transaction_details?.mode){   
                    if(comboBookings){
                        combobookingsCreateBookings(res?.data?.customer?.customer_id);
                    }else{
                        if(formData?.shot_id && formData?.shot_name && formData?.shot_mode && formData?.path_ids){
                            await createBookingApi(res?.data?.customer?.customer_id);
                        }else{
                            setLoading(false);
                            toast.info("Kindly Must Be Select Shot");
                        }
                        
                    }
                }else{
                    setLoading(false);
                    toast.info("Kindly Must Be Filled Nationality and Payment Mode");
                } 
            }else{
                toast.info(res?.data?.message);
                setLoading(false);
            }
  
        }catch(err){
            setLoading(false);
        }
    }

    const handleSubmit= async (e)=>{
        e.preventDefault();
       getCustomerDetailsApiCall();
    };

    const handleSublocation=(e)=>{
        setOpenSublocation(e.target.checked);
        // setAmount(0);
    }
   
    const handleBulkBooking=()=>{
        setComboBooings(true);
        setOpenCombo(false);
        setFormData((prev)=>({...prev , sub_location_id:"" , sub_location_name:"" , transaction_details:{...prev?.transaction_details , sub_location_id:""}}));
        if(formData?.transaction_details?.nationality === "indian"){
         setAmount(4000);
        }else if(formData?.transaction_details?.nationality === "foreigner"){
           setAmount(6000);
        }else{
            toast.info("Kindly Select Nationality");
        };
        
        setFilteredShots([]);
        setSelectedSublocation();
    }
   
    console.log("formData :-->", formData);
    console.log("amount", amount);
    console.log("shots : " , shots);
 
  return (
    <>
    


    
    <div className='relative flex flex-col justify-center items-center my-5 h-full'>

    {loading && <div className='z-20 w-screen h-full flex items-center justify-center bg-[#ffffffcc] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
        <SimpleLogoLoader logoWidth="100px"/> </div> }

          <h1 className='my-2 text-2xl bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text'>Booking Creating Form</h1>
        <form action="" onSubmit={handleSubmit} className='flex flex-wrap w-[60%] md:w-[70%] mmd:w-[90%] sm:w-full shadow-2xl rounded-xl p-5'>

            <div className="m-5 w-full">
                <label htmlFor="price" className="mb-2 block text-sm font-medium">Name</label>
                <div className="relative">
                    <input type="text" id="customer_name" name="customer_name" onChange={handleFormChange} value={formData?.customer_name ? formData?.customer_name : ""} className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-9 pr-16 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Name" required/>
                </div>
            </div>

           
            <div className="m-5 w-full">
                <label htmlFor="phone" className="mb-2 block text-sm font-medium">Phone number</label>
                <div className="relative">
                    {/* <input type="text" id="phone_number" name="phone_number" onChange={handleFormChange} value={formData?.phone_number ? formData?.phone_number : ""}  className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-24 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="+91 00000-00000" required />
                    <div className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-500">
                    <label htmlFor="country-code" className="sr-only">Country</label>
                    <select id="country-code" name="country-code" className="block w-full rounded-md border-transparent focus:border-blue-600 focus:ring-blue-600">
                        <option>US</option>
                        <option>CA</option>
                        <option>EU</option>
                    </select> */}
                    {/* </div> */}
                    <PhoneInput
                        className="rounded-full"
                        international
                        placeholder="+91 00000-00000"
                        defaultCountry="IN"
                        value={formData?.phone_number ? formData?.phone_number : ""}
                        onChange={handlePhoneNumberChange}/>
                        
                </div>
            </div>

            <div className="m-5 w-full">
                <label htmlFor="price" className="mb-2 block text-sm font-medium">Group Size</label>
                <div className="relative">
                    <input type="text" id="no_of_customers" name="no_of_customers" onChange={handleFormChange} value={formData?.transaction_details?.no_of_customers ? formData?.transaction_details?.no_of_customers : ""} className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-9 pr-16 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Group Size" required/>
                </div>
            </div>
          
            <div className='m-5 w-full '>
            <p className="font-medium mb-1 text-gray-500 ">Nationality ?</p>
            <div className="flex sm:flex-col gap-6">
                {[{name:"Indian" , val:"indian"} , {name:"Foreigner" , val:"foreigner"}]?.map((data,idx)=>(
                        <div key={idx} className="w-full relative flex  items-center justify-center rounded-xl bg-gray-50 py-3 font-medium text-gray-700">
                        <input className="hidden" type="radio" name="nationality" id={data?.val} onChange={handleFormChange}  checked={formData?.transaction_details?.nationality === data?.val}/>
                        <label className={`${formData?.transaction_details?.nationality === data?.val ? "border-blue-400 peer-checked:bg-blue-200" :"" } absolute top-0 h-full w-full cursor-pointer rounded-xl border`} htmlFor={data?.val}> </label>
                        <div className={`${formData?.transaction_details?.nationality === data?.val ? "border-transparent bg-blue-400 ring-2     " :"" }absolute left-4 h-5 w-5 rounded-full border-2 border-gray-300 bg-gray-200 ring-blue-400 ring-offset-2`}></div>
                        <span className="pointer-events-none z-10">{data?.name}</span>
                    </div>
                ))}
            </div>
            </div>
            
            
            
            <div className="w-full flex flex-wrap gap-5 justify-between m-5">
                <div>
                    <p className="font-medium mb-1 text-gray-500 ">Select Sublocation</p>
                    <input className="peer hidden" type="checkbox" name="select-1" id="select-1" value={openSublocation}  onChange={handleSublocation} />
                    <label htmlFor="select-1" className="flex w-full cursor-pointer select-none rounded-lg border py-3 px-4 text-center pt-2 text-sm text-gray-700 ring-blue-400 peer-checked:ring">{formData?.sub_location_name ? formData?.sub_location_name : "Select Sublocation" }</label>
            
                    <ul className={`${openSublocation ? "max-h-56 py-3 " : "max-h-0 hidden"}select-none flex-col overflow-hidden rounded-b-lg shadow-xl transition-all duration-300  bg-white`}>
                        {subLocationList?.map((loc,idx)=>(
                            <li onClick={()=>{
                                handleGetShots(loc);
                                setAmount(0);
                            }} key={idx} className={`peer-checked:ring ${formData?.sub_location_name === loc?.sub_location_name ? "bg-blue-500 text-white" : ''} cursor-pointer px-3 py-2 text-sm text-gray-500 hover:bg-blue-500 hover:text-white`}>{loc?.sub_location_name}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <p className="font-medium mb-1 text-gray-500 ">Select BulkBookings</p>
                    <input className="peer hidden" type="checkbox" name="select-2" id="select-2" value={openCombo}  onChange={(e)=>setOpenCombo(e.target.checked)} />
                    <label htmlFor="select-2" className="flex w-full cursor-pointer select-none rounded-lg border py-3 px-4 text-center pt-2 text-sm text-gray-700 ring-blue-400 peer-checked:ring">{comboBookings ? "Chandani , Jasmandir , Domb" : "Select Combo" }</label>
            
                    <ul className={`${openCombo ? "max-h-56 py-3 " : "max-h-0 hidden"}select-none flex-col overflow-hidden rounded-b-lg shadow-xl transition-all duration-300  bg-white`}>
                        {bulkBookingsList?.map((loc,idx)=>(
                            <li onClick={handleBulkBooking} key={idx} className={`peer-checked:ring ${comboBookings ? "bg-blue-500 text-white" : ''} cursor-pointer px-3 py-2 text-sm text-gray-500 hover:bg-blue-500 hover:text-white`}>{loc}</li>
                        ))}
                    </ul>
                </div>
            </div>
            
            <div className="m-5 w-full">
                <label htmlFor="price" className="mb-2 block text-sm font-medium">Custom Price &#8377;</label>
                <div className="relative">
                    <input type="text" id="custom_price" name="custom_price" onChange={handleFormChange} value={formData?.transaction_details?.final_amount !== "" ? formData?.transaction_details?.final_amount : ""} className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-9 pr-16 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Custom Price" />
                </div>
            </div>
            {comboBookings && <div className="w-fit space-y-2 m-5"> <h1>{amount}</h1> </div>}
           {filteredShots?.length > 0 &&  <div className="w-fit space-y-2 m-5">
                <h2 className="text- font-medium text-gray-700">Select your Shots</h2>
                <h1>{amount}</h1>
             <div className='flex gap-4 flex-wrap'>
                {filteredShots?.map((shots , idx)=>(
                    <div key={idx} className="relative flex min-w-40 items-center rounded-lg bg-gray-50 py-2 px-4 pl-14 font-medium text-gray-700">
                    <input className="peer hidden" type="radio" name="shots" id={shots?.shot_name} onChange={(e)=>handleFormChange(e,shots)} checked={formData?.shot_id === shots?.shot_id} />
                    <label className="absolute left-0 top-0 h-full w-full cursor-pointer rounded border peer-checked:border-blue-600 peer-checked:bg-blue-100" htmlFor={shots?.shot_name}> </label>
                    <div className="absolute left-4 h-3 w-3 rounded border-2 border-gray-300 bg-gray-200 ring-blue-600 ring-offset-2 peer-checked:border-transparent peer-checked:bg-blue-600 peer-checked:ring-2"></div>
                    <span className="pointer-events-none z-10">{shots?.shot_name}</span>
                    </div>
                ))}
                
            </div>
            </div>}

            <div className="w-fit space-y-2 m-5">
                <h2 className="text- font-medium text-gray-700">Select your Payment Mode</h2>
             <div className='flex gap-4 flex-wrap'>
                {paymentModeArr?.map((mode,idx)=>(
                    <div key={idx} className="relative flex w-60 items-center rounded bg-gray-50 py-2 px-4 pl-14 font-medium text-gray-700">
                    <input className="peer hidden" type="radio" name="paymentMode" id={mode}  onChange={(e)=>handleFormChange(e)} checked={formData?.transaction_details?.mode === mode}/>
                    <label className="absolute left-0 top-0 h-full w-full cursor-pointer rounded border peer-checked:border-blue-600 peer-checked:bg-blue-100" htmlFor={mode}> </label>
                    <div className="absolute left-4 h-3 w-3 rounded border-2 border-gray-300 bg-gray-200 ring-blue-600 ring-offset-2 peer-checked:border-transparent peer-checked:bg-blue-600 peer-checked:ring-2"></div>
                    <span className="pointer-events-none z-10">{mode?.toUpperCase()}</span>
                </div>
                ))}
                
            </div>
            </div>
            
            <div className='w-full'>
                <button type='submit' className="mt-4 rounded-full bg-blue-800 px-10 py-2 font-semibold text-white">Submit</button>
            </div>
            
        </form>
     
    </div>
    
         <AmberFortFormModal modalOpen={amberQrModal} onCloseModal={()=>setAmberQrModal(false)} resObj={resObj}/>
    </>
  )
}

export default AmberFortBookingPage